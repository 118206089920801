import React, { useEffect, useState } from 'react';
import { LandingPage } from './LandingPage';
import './App.css'; // Assuming you have some global styles

const App: React.FC = () => {
  const [animationFinished, setAnimationFinished] = useState(true);

  // useEffect(() => {
  //   // Placeholder for the animation logic
  //   // After the animation, set animationFinished to true
  //   setTimeout(() => setAnimationFinished(true), 3000); // Simulate an animation with timeout
  // }, []);

  return (
    <div className="App">
      {!animationFinished ? (
        <div className="entrance-animation">
          {/* Unique animation goes here */}
          <h1 className="hero-headline">earth is cooked.</h1>
        </div>
      ) : (
        <LandingPage />
      )}
    </div>
  );
};

export default App;