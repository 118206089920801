import React from "react";
import { useEffect } from "react";
import "./styles/LandingPage.css"; // Ensure this path is correct
import "./styles/WeatherEffects.css";
import earthTransparent from "./assets/earthTransparent.png"; // Ensure this path is correct
import Plate from "./assets/plate.png"; // Ensure this path is correct
import CoinImage from "./assets/coin-transparent.png";
import { useSpring, animated } from "react-spring";
import { FadeInSection } from "./FadeInSection";
import fireOverlayVideo from "./assets/overlays/fireborder.mp4";
import smokeOverlayVideo from "./assets/overlays/smoke.mp4";

export const LandingPage: React.FC = () => {
  const fadeIn = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 300,
    config: { duration: 1000 }, // Duration in milliseconds
  });

  useEffect(() => {
    const adjustVideoSize = () => {
      const videos = document.querySelectorAll(".fire-overlay, .smoke-overlay");
      videos.forEach((video) => {
        if (video.parentElement) {
          const { width, height } = video.parentElement.getBoundingClientRect();
          video.setAttribute("width", `${width}px`);
          video.setAttribute("height", `${height}px`);
        }
      });
    };

    // Adjust on mount and window resize
    adjustVideoSize();
    window.addEventListener("resize", adjustVideoSize);

    // Cleanup
    return () => window.removeEventListener("resize", adjustVideoSize);
  }, []);

  return (
    <animated.div style={fadeIn} className="landing-page">
      <div className="hero-section">
        <video autoPlay loop muted playsInline className="fire-overlay">
          <source src={fireOverlayVideo} type="video/mp4" />
        </video>
        <div className="hero-text-container">
          <div className="heading-container">
            <h1 className="hero-headline">earth is cooked.</h1>
            <h2 className="hero-subheadline">$COOKD</h2>
            <p className="hero-by-text">by The Secret Society</p>
          </div>
          <div className="buttons-container">
            <a
              href="https://twitter.com/earthiscookd"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
            >
              Twitter
            </a>
            <a
              href="https://t.me/earthiscookd"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
            >
              Telegram
            </a>
          </div>
          <div className="hero-cta">
            <p
              className="address"
              onClick={() =>
                navigator.clipboard.writeText(
                  "53YeF6s9oTLXXDoGouF1biV4kMZkKDf5RTy6iRhng2eT"
                )
              }
            >
              53YeF6s9oTLXXDoGouF1biV4kMZkKDf5RTy6iRhng2eT
            </p>{" "}
          </div>
          <p>
            Wars, environmental challenges, and a world in shambles. We are here
            to help.
          </p>
        </div>
      </div>

      <FadeInSection>
        <section className="about-section">
          <img
            src={Plate}
            alt="Earth is cooked on a plate"
            className="about-image"
          />
          <div className="about-content">
            <h2 className="about-title">About Our Project</h2>
            <p className="about-text">
              Our project is committed to making a positive impact on the world.
              With our initial purchase of 5 SOL, we are setting the stage for a
              series of charitable contributions and strategic investments. We
              pledge to allocate 50% of our revenue to support various charities
              outlined in our roadmap. The remaining 50% will be invested in
              purchasing $SMS tokens. The SMS tokens will be used for two
              purposes: rewarding our loyal holders and amplifying our marketing
              initiatives.
            </p>
            <p className="about-text">
              The team behind this initiative is the same dedicated team that
              developed the SMS platform. We are leveraging our experience and
              resources to ensure the success and integrity of this new venture
              and our chairholders.
            </p>
            <p className="charity-wallet">
              Dev Wallet: 9TEBDKuDLPqp7eGMKPFqKkfiurzSp6EdajftW6xc8nds
            </p>
            <p className="charity-wallet">
              Donation Wallet: AUAD2MNoNhg1hYhjZ7GRoQxpGkCsqSdy9bi9yf7FiExn
            </p>
            <div className="sister-project">
              An official sister project of The Secret Society, we invite you to
              explore and join our cause.
              <br />
              <a
                href="https://soldmysol.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Visit soldmysol.com
              </a>
              <img
                src={CoinImage}
                alt="The Secret Society Coin"
                className="coin-image"
              />
            </div>
          </div>
        </section>
      </FadeInSection>
      <FadeInSection>
        <section className="roadmap-section">
          <video autoPlay loop muted playsInline className="smoke-overlay">
            <source src={smokeOverlayVideo} type="video/mp4" />
          </video>
          <div className="roadmap-content">
            <h2 className="roadmap-title">Roadmap</h2>
            <p className="roadmap-text">
              We are committed to donating 50% of any profits to the following
              causes as we progress through our roadmap:
            </p>
            <div className="roadmap-steps">
              <p className="roadmap-item">✅ Launch on pump.fun</p>
              <p className="roadmap-item">✅ Reach Raydium (10min)</p>
              <p className="roadmap-item">- Reach the Moon</p>
              <p className="roadmap-item">- Reach the Stars</p>
              <p className="roadmap-item">- Touch Jupiter</p>
              <p className="roadmap-item">
                - Build the biggest meme coin forest
              </p>
              <p> </p>
              <p className="roadmap-item">
                Depending on the success of this project, 5m holders of $COOKD
                may be eligible for a spot at The Secret Society Table
              </p>
            </div>
            <p className="roadmap-item">Dev Wallet:</p>
            <p className="roadmap-item charity-wallet">
              9TEBDKuDLPqp7eGMKPFqKkfiurzSp6EdajftW6xc8nds
            </p>
            <p className="roadmap-item">Donation Wallet:</p>
            <p className="roadmap-item charity-wallet">
              AUAD2MNoNhg1hYhjZ7GRoQxpGkCsqSdy9bi9yf7FiExn
            </p>

            <div>
              <a
                href="https://plantwithpurpose.org/plant-a-tree/?gad_source=1"
                className="roadmap-causes"
                target="_blank"
                rel="noopener noreferrer"
              >
                Plant with Purpose (330 Trees Planted)
              </a>

              <a
                href="https://www.rainforesttrust.org/"
                className="roadmap-causes"
                target="_blank"
                rel="noopener noreferrer"
              >
                Rainforest Trust
              </a>
              <a
                href="https://protect.worldwildlife.org/page/53256/donate/1"
                className="roadmap-causes"
                target="_blank"
                rel="noopener noreferrer"
              >
                Keep Nature Wild
              </a>
              <a
                href="https://www.earthday.org/donate/"
                className="roadmap-causes"
                target="_blank"
                rel="noopener noreferrer"
              >
                Earthday.org
              </a>
            </div>
          </div>
          <div className="roadmap-image">
            <img src={earthTransparent} alt="City Transparent" />
          </div>
        </section>
      </FadeInSection>

      {/* <section id="tokenomics" className="section">
        <h2>Tokenomics</h2>
        <p>
          Understanding the economics behind our token and how it contributes to
          our goals...
        </p>
      </section> */}
      {/* Additional sections as needed */}
    </animated.div>
  );
};
